<template>
  <ion-page>
    <Header title="Help" />
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">Help</ion-title>
        </ion-toolbar>
      </ion-header>
      
      <ion-card>
        <ion-card-header>
          <h1>SlapTrack Help</h1>
        </ion-card-header>
        <ion-card-content>
          <ul>
            <li>Click "Play" to start the tracks</li>
            <li>Click "Stop" to stop the tracks</li>
            <li>Controls can be changed while the track is stopped
              <ul>
                <li>Change Beats Per Minute to change the speed of the track</li>
                <li>Change Time Signature to alter the number of body percussion tracks per measure</li>
              </ul>
            </li>
            <li>Click "Settings" at the bottom of the site to change the body percussion images shown</li>
          </ul>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonCard, IonCardHeader, IonCardContent } from '@ionic/vue';
import Header from '@/components/Header.vue';

export default  {
  name: 'Help',
  components: { Header, IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonCard, IonCardHeader, IonCardContent }
}
</script>